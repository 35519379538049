'use client';

import { cn } from '@/lib/utils/cn';
import { MessagesSquareIcon, SearchIcon, User2Icon } from 'lucide-react';
import { usePathname } from 'next/navigation';
import { LinkButton } from '@/components/ui/link-button';
import { useIsMobile } from '@/lib/client/hooks/useIsMobile';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@/components/ui/tooltip';
import { useEffect, useState } from 'react';
import { useTranslations } from 'next-intl';

const linkItems = [
  {
    name: 'inbox',
    path: '/app/inbox',
    label: 'Inbox',
    icon: <MessagesSquareIcon className='w-5 h-5' />,
  },
  {
    name: 'jobs',
    path: '/app/explore',
    label: 'Jobs',
    icon: <SearchIcon className='w-5 h-5' />,
  },
  {
    name: 'profile',
    path: '/app/profile',
    label: 'My Profile',
    icon: <User2Icon className='w-5 h-5' />,
  },
];

export function HeaderNav() {
  const t = useTranslations('candidate.header.nav');
  const pathname = usePathname();
  const [selectedTab, setSelectedTab] = useState<string>(pathname);
  const { isMobile } = useIsMobile();

  useEffect(() => {
    setSelectedTab(pathname);
  }, [pathname]);

  return (
    <nav>
      <ul className='flex items-baseline sm:items-center'>
        {linkItems.map((item) => (
          <li key={item.path}>
            <Tooltip>
              <TooltipTrigger asChild>
                <LinkButton
                  href={item.path}
                  variant='ghost-brand'
                  size='sm'
                  onClick={() => setSelectedTab(item.path)}
                  className={cn(
                    'text-foreground hover:text-brand px-2 sm:px-3 h-fit',
                    selectedTab === item.path &&
                      'text-brand bg-brand-foreground dark:bg-brand-900'
                  )}
                >
                  {isMobile ?
                    <div className='flex flex-col items-center text-xs font-semibold'>
                      {item.icon}
                      <div className='max-w-[8ch] whitespace-break-spaces text-center'>
                        {t(item.name)}
                      </div>
                    </div>
                  : t(item.name)}
                </LinkButton>
              </TooltipTrigger>
              <TooltipContent className='sm:hidden'>
                {item.label}
              </TooltipContent>
            </Tooltip>
          </li>
        ))}
      </ul>
    </nav>
  );
}
