'use client';

import { BusinessAppHeader } from '@/components/BusinessAppHeader';
import { CandidateAppHeader } from '@/components/CandidateAppHeader';
import { useParams, usePathname, useRouter } from 'next/navigation';
import { ReactNode, useEffect } from 'react';
import { Loader } from '@/components/common/loaders/loaders';
import { useAccountStore } from '@/lib/client/store/account.store';
import { useAuth } from '@/lib/client/hooks/useAuth';
import { useProfile } from '@/modules/profile/profile.queries';
import { useListBusinessesForUser } from '@/modules/business/business.queries';

export default function AppLayout({ children }: { children: ReactNode }) {
  const { isLoggedIn, isLoading: isAuthLoading } = useAuth({ require: true });
  const router = useRouter();
  const params = useParams();
  const pathname = usePathname();
  const account = useAccountStore();
  const { data: profile, isLoading: isProfileLoading } = useProfile();
  const { data: businesses, isLoading: isBusinessesLoading } =
    useListBusinessesForUser();

  const hideHeaders =
    pathname.startsWith('/app/welcome') ||
    pathname.endsWith('/candidate/jobreel') ||
    (pathname.startsWith('/app/my-jobs') &&
      !!params['jobHash'] &&
      !!params['applicationHash']);

  const isLoading = isAuthLoading || account.isLoading || isBusinessesLoading;

  const hasAccount = !!account.type || !!profile || !!businesses;

  useEffect(() => {
    if (isLoading || !isLoggedIn || isProfileLoading) return;

    if (!hasAccount) {
      router.replace('/onboarding/mode');
      return;
    }

    if (pathname === '/app') {
      if (account.type === 'business') {
        router.replace('/app/my-jobs');
      }
      if (account.type === 'candidate') {
        router.replace('/app/explore');
      }
    }
  }, [
    account.type,
    isProfileLoading,
    pathname,
    router,
    isLoading,
    hasAccount,
    isLoggedIn,
  ]);

  if (isLoading) {
    return (
      <main className='min-h-screen/header sm:min-h-screen flex items-center justify-center'>
        <Loader className='w-10 h-10 text-brand' />
      </main>
    );
  }

  if (!hasAccount) {
    return null;
  }

  return (
    <>
      {hideHeaders ?
        null
      : account.type === 'business' ?
        <BusinessAppHeader />
      : <CandidateAppHeader />}
      <main className='min-h-screen/header-2 sm:min-h-screen/header'>
        {children}
      </main>
    </>
  );
}
