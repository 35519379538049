'use client';

import { HeaderShadow } from '@/components/CandidateAppHeader/header-shadow';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { BriefcaseIcon, HouseIcon, SearchIcon } from 'lucide-react';
import { cn } from '@/lib/utils/cn';
import { Tooltip, TooltipContent, TooltipTrigger } from '../ui/tooltip';
import { LinkButton } from '../ui/link-button';
import { useIsMobile } from '@/lib/client/hooks/useIsMobile';
import { useEffect, useState } from 'react';
import { Logo } from '@/components/icons/logo';

const navItems = [
  {
    label: 'My Jobs',
    path: '/app/my-jobs',
    icon: <HouseIcon className='w-5 h-5 sm:w-6 sm:h-6' />,
    isLink: true,
  },
  {
    label: 'Explore',
    path: '/app/explore',
    icon: <SearchIcon className='w-5 h-5 sm:w-6 sm:h-6' />,
    isLink: true,
  },
  {
    label: 'My Business',
    path: '/app/my-business',
    icon: <BriefcaseIcon className='w-5 h-5 sm:w-6 sm:h-6' />,
  },
];

export function BusinessAppHeader() {
  const pathname = usePathname();
  const [selectedTab, setSelectedTab] = useState<string>(pathname);
  const { isMobile } = useIsMobile();

  useEffect(() => setSelectedTab(pathname), [pathname]);

  return (
    <header
      id='app-header'
      className='sticky top-0 bg-white z-50 border-b h-20'
    >
      <div className='relative container flex items-center justify-between h-full'>
        <Link href='/app/my-jobs'>
          <Logo className='w-24 sm:w-28 text-brand' />
        </Link>

        <nav>
          <ul className='flex items-center gap-2'>
            {navItems.map((item) => (
              <li key={item.path}>
                <Tooltip>
                  <TooltipTrigger>
                    <LinkButton
                      size={'sm'}
                      variant='ghost'
                      href={item.path}
                      className={cn(
                        'text-foreground hover:text-brand px-0 sm:px-3',
                        selectedTab === item.path &&
                          'text-brand bg-brand-foreground dark:bg-brand-900'
                      )}
                    >
                      {isMobile ?
                        <div className='flex flex-col items-center text-xs font-semibold'>
                          {item.icon}
                          {item.label}
                        </div>
                      : item.label}
                    </LinkButton>
                  </TooltipTrigger>
                  <TooltipContent>{item.label}</TooltipContent>
                </Tooltip>
              </li>
            ))}
          </ul>
        </nav>

        <HeaderShadow />
      </div>
    </header>
  );
}

export default BusinessAppHeader;
