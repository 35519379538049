import { LocaleSelector } from '../common/locale-selector';
import { Logo } from '../icons/logo';
import { HeaderNav } from './header-nav';
import { HeaderShadow } from './header-shadow';
import { cn } from '@/lib/utils/cn';
import Link from 'next/link';

export function CandidateAppHeader() {
  return (
    <header
      id='app-header'
      className={cn('sticky top-0 bg-white z-50 border-b h-20')}
    >
      <div className='relative container flex items-center justify-between h-full'>
        <Link
          href='/app/explore'
          className='rounded-full focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2'
        >
          <Logo className='w-28 text-ruby' />
        </Link>
        <div className='flex items-center gap-4'>
          <HeaderNav />
        </div>
        <HeaderShadow />
      </div>
    </header>
  );
}

export default CandidateAppHeader;
